import React from "react";
import { useTranslation } from "react-i18next";

function PopUp() {
  const { t: translate } = useTranslation();
  return (
    <section id="payment_select" className="services-section spad cop none">
      <div className="card-container w-3/5 md:w-2/5">
        <div className="card w-full">
          <button id="close_payment_modal" className="dismiss">
            x
          </button>
          <div className="header">
            <div className="content">
              <ul className="message">
                <div className="w-full flex flex-col items-center justify-center wrap px-4">
                  <button
                    data-translate="cash_pay"
                    id="cash_payment"
                    className="mt-3 px-3 py-2 bg-slate-600 text-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-[13.22rem] lg:w-[54%] rounded-md sm:text-sm focus:ring-1"
                  >
                    {translate("cash_pay")}
                  </button>
                  <select
                    name="locations"
                    id="locations"
                    className="hidden mt-3 px-3 py-2 border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-[13.22rem] lg:w-[54%] rounded-md sm:text-sm focus:ring-1"
                  >
                    <option value="Ünalmar Market - Haspolat">
                      Ünalmar Market - Haspolat
                    </option>
                    <option value="Uzmar Market - Alayköy">
                      Uzmar Market - Alayköy
                    </option>
                    <option value="Tom &amp; Bony Market - Lefkoşa">
                      Tom &amp; Bony Market - Lefkoşa
                    </option>
                    <option value="Servet Karadağ Barber Shop - Lefkoşa">
                      Servet Karadağ Barber Shop - Lefkoşa
                    </option>
                    <option value="Mineraliköy Kooperatifi - Lefkoşa">
                      Mineraliköy Kooperatifi - Lefkoşa
                    </option>
                    <option value="AlemNet Merkez ofis - Dereboyu">
                      AlemNet Merkez ofis - Dereboyu
                    </option>
                    <option value="Alemdar Network Merkez ofis - Lefkoşa">
                      Alemdar Network Merkez ofis - Lefkoşa
                    </option>
                  </select>
                </div>

                <div className="w-full flex flex-col items-center justify-center wrap px-4">
                  <button
                    data-translate="go_location"
                    id="go_location"
                    className="hidden mt-3 px-3 py-2 bg-green-600 text-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-[13.22rem] lg:w-[54%] rounded-md sm:text-sm focus:ring-1"
                  >
                    {translate("go_location")}
                  </button>
                </div>
                <div className="w-full flex flex-col items-center justify-center wrap px-4">
                  <button
                    data-translate="bank_trans_btn"
                    id="bank_trans_btn"
                    className="mt-3 px-3 py-2 bg-slate-600 text-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-[13.22rem] lg:w-[54%] rounded-md sm:text-sm focus:ring-1"
                  >
                    {translate("bank_trans_btn")}
                  </button>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PopUp;
