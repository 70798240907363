import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
function About() {
  const { t: translate } = useTranslation();
  return (
    <>
      <div className="mt-[5rem] md:mt-[6rem]">
        <div className="breadcrumb-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb__option">
                  <Link data-translate="home_txt" to="/" previewlistener="true">
                    <span className="fa fa-home"></span> {translate("home_txt")}
                  </Link>
                  <span data-translate="about_txt">
                    {translate("about_txt")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="about-section spad">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="about__img">
                  <img
                    src="https://freepngimg.com/save/63201-information-network-computer-internet-data-icon/877x593"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about__text">
                  <h2 data-translate="wlc_txt">{translate("wlc_txt")}</h2>
                  <p data-translate="wlc_p">{translate("wlc_p")}</p>
                  <div className="about__achievement">
                    <div className="about__achieve__item">
                      <span className="fa fa-user-o"></span>
                      <h4 className="achieve-counter">2468</h4>
                      <p data-translate="clnt">{translate("clnt")}</p>
                    </div>
                    <div className="about__achieve__item">
                      <span className="fa fa-edit"></span>
                      <h4 className="achieve-counter">2468</h4>
                      <p data-translate="pckg">{translate("pckg")}</p>
                    </div>
                    <div className="about__achieve__item">
                      <span className="fa fa-cog"></span>
                      <h4 className="achieve-counter">2468</h4>
                      <p data-translate="instls">{translate("instls")}</p>
                    </div>
                  </div>
                  <Link
                    className="primary-btn"
                    data-translate="get_strt"
                    to="/pricing"
                    previewlistener="true"
                  >
                    {translate("get_strt")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="feature-section spad">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title">
                  <h3 data-translate="yr_int">{translate("yr_int")}</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="feature__item">
                  <span className="fa fa-cloud-upload"></span>
                  <h5 data-translate="adv_ap">{translate("adv_ap")}</h5>
                  <p data-translate="adv_ap_d">{translate("adv_ap_d")}</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="feature__item">
                  <span className="fa fa-sliders"></span>
                  <h5 data-translate="ht_sp">{translate("ht_sp")}</h5>
                  <p data-translate="ht_sp_d">
                    {translate("ht_sp_d")} <br />
                    <br />
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="feature__item">
                  <span className="fa fa-database"></span>
                  <h5 data-translate="fb_pk">{translate("fb_pk")}</h5>
                  <p data-translate="fb_pk_p">{translate("fb_pk_p")}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default About;
