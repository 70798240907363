import React from "react";

function LoaderComponent() {
  return (
    <div id="preloder">
      <div className="loader"></div>
    </div>
  );
}

export default LoaderComponent;
