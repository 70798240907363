import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
function BankAccounts() {
  const { t: translate } = useTranslation();
  const handleCopyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert("IBAN copied to clipboard: " + text);
      })
      .catch((err) => {
        console.error("Unable to copy to clipboard", err);
      });
  };
  return (
    <>
      <div className="mt-[5rem] md:mt-[6rem]">
        <div className="breadcrumb-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb__option">
                  <Link data-translate="home_txt" to="/" previewlistener="true">
                    <span className="fa fa-home"></span> {translate("home_txt")}
                  </Link>
                  <span data-translate="bnk_a">{translate("bnk_a")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section class="question-section spad">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="section-title">
                  <h3 data-translate="bnk_a">{translate("bnk_a")}</h3>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="question__accordin">
                  <div class="accordion" id="accordionExample">
                    <div class="card">
                      <div class="card-heading active">
                        <div class="active">IsBank IBAN</div>
                      </div>
                      <div class=" show">
                        <div class="card-body">
                          <p
                            className="mt-3"
                            onClick={() =>
                              handleCopyToClipboard(
                                "TR770006400000168130017682"
                              )
                            }
                          >
                            IBAN : TR770006400000168130017682
                            <br />
                            <strong>Alemdar Network LTD</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="about__img">
                  <img
                    src="https://cdn3d.iconscout.com/3d/premium/thumb/wallet-4521221-3754834.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default BankAccounts;
