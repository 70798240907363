import React, { useState } from "react";
import { db } from "../firebase-config";
import { collection, addDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next";
function ContactForm() {
  const { t: translate } = useTranslation();
  const dataCollectionRef = collection(db, "alemnet-contact-us");
  const [username, setUsername] = useState();
  const [usermail, setUsermail] = useState();
  const [message, setMessage] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const fireSubmit = async (event) => {
    event.preventDefault();

    // Email validation
    if (!usermail) {
      alert("Please enter a valid email.");
      return;
    }

    // Send data to Firebase
    try {
      await addDoc(dataCollectionRef, {
        name: username,
        mail_field: usermail,
        message: message,
      });

      setIsSubmitted(true);

      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      // setSuccessMessage("Error, try again or reload !");
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="lg:w-[100%] flex flex-col items-center">
      <div className="contact-form spad w-[90%]">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 data-translate="sned">{translate("sned")}</h3>
              <form
                method="post"
                id="wf-form-Styleguide-Form"
                name="wf-form-Styleguide-Form"
              >
                <div className="input-list">
                  <input
                    type="text"
                    name="fullName"
                    onChange={(event) => {
                      setUsername(event.target.value);
                    }}
                    value={username}
                    placeholder={translate("ent_full_name")}
                  />
                  <input
                    type="text"
                    name="email_e"
                    placeholder={translate("ent_email")}
                    onChange={(event) => {
                      setUsermail(event.target.value);
                    }}
                    value={usermail}
                  />
                </div>
                <textarea
                  onChange={(event) => {
                    setMessage(event.target.value);
                  }}
                  placeholder={translate("ent_question")}
                  name="message"
                ></textarea>
                <button
                  type="submit"
                  className="site-btn"
                  onClick={fireSubmit}
                  style={{ backgroundColor: "#4c57d6" }}
                >
                  {translate("send_m")}
                </button>
              </form>
              {isSubmitted && (
                <div className="form-message-success w-form-done">
                  <div data-translate="frm_4">{translate("frm_4")}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
