import React from "react";
import Packages from "../components/Packages";
import PopUp from "../components/PopUp";
import Banner from "../components/Banner";
import Acheivement from "../components/Acheivement";
import VisitUs from "../components/VisitUs";

function Home({ handleHidePack }) {
  return (
    <div className="mt-[5rem] md:mt-[6rem]">
      <Banner />
      <PopUp />
      <Packages handleHidePack={handleHidePack} />
      <Acheivement />
      <VisitUs />
    </div>
  );
}

export default Home;
