import React from "react";
import { useTranslation } from "react-i18next";
function Acheivement() {
  const { t: translate } = useTranslation();
  return (
    <section
      className="achievement-section set-bg spad"
      data-setbg="img/achievement-bg.jpg"
      style={{ backgroundImage: `url("img/achievement-bg.jpg")` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="achievement__item">
              <span className="fa fa-user-o"></span>
              <h2 className="achieve-counter">8950</h2>
              <p data-translate="clnt">{translate("clnt")}</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="achievement__item">
              <span className="fa fa-edit"></span>
              <h2 className="achieve-counter">24683</h2>
              <p data-translate="pckg">{translate("pckg")}</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="achievement__item">
              <span className="fa fa-clone"></span>
              <h2 className="achieve-counter">2468</h2>
              <p data-translate="dscnt">{translate("dscnt")}</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="achievement__item">
              <span className="fa fa-cog"></span>
              <h2 className="achieve-counter">10</h2>
              <p>Years</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Acheivement;
