import React from "react";
import { Link } from "react-router-dom";
import ContactForm from "../components/ContactForm";
import { useTranslation } from "react-i18next";
function Contact() {
  const { t: translate } = useTranslation();
  return (
    <div className="mt-[5rem] md:mt-[6rem]">
      <div className="breadcrumb-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb__option">
                <Link data-translate="home_txt" to="/">
                  <span className="fa fa-home"></span> {translate("home_txt")}
                </Link>
                <span data-translate="contact_txt">
                  {translate("contact_txt")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="contact-section spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="contact__text">
                <h3 data-translate="cnt_i">{translate("cnt_i")}</h3>
                <p data-translate="cnt_i_p">{translate("cnt_i_p")}</p>
                <ul>
                  <li>
                    <span className="fa fa-map-marker"></span>
                    <h5 data-translate="addr">{translate("addr")}</h5>
                    <p>
                      MEHMET AKİF CADDESİ NO:63
                      <br />
                      DEREBOYU LEFKOŞA
                    </p>
                  </li>
                  <li>
                    <span className="fa fa-mobile"></span>
                    <h5 data-translate="tel">{translate("tel")}</h5>
                    <p>
                      <a href="tel:+90 5428772009">+90 5428772009</a>
                    </p>
                  </li>
                  <li>
                    <span className="fa fa-envelope"></span>
                    <h5 data-translate="mail">{translate("mail")}</h5>
                    <p>
                      <a href="mailto:alemdar_488@hotmail.com">
                        alemdar_488@hotmail.com
                      </a>
                    </p>
                  </li>
                </ul>
                <div className="contact__social">
                  <a href="https://www.facebook.com" className="facebook">
                    <i className="fa fa-facebook"></i>
                  </a>
                  <a href="https://www.twitter.com" className="twitter">
                    <i className="fa fa-twitter"></i>
                  </a>
                  <a href="https://www.youtube.com" className="youtube">
                    <i className="fa fa-youtube-play"></i>
                  </a>
                  <a href="https://www.instagram.com" className="instagram">
                    <i className="fa fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-8" id="maps">
              <div className="map">
                <iframe
                  title="ourr_address"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13043.312411532235!2d33.3510208!3d35.1858367!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14de17a022c17c39%3A0xc9fcfedbbb7edaee!2sALEMNET!5e0!3m2!1sen!2s!4v1700833167739!5m2!1sen!2s"
                  height="515"
                  style={{ border: "0" }}
                  allowFullScreen
                  aria-hidden="false"
                  tabIndex={0}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactForm />
    </div>
  );
}

export default Contact;
