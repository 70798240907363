import axios from "axios";
import { collection, getDocs } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import React, { useEffect, useRef, useState } from "react";
import { db, imageDb } from "../firebase-config";
import { v4 as imgUniqueId } from "uuid";
import { useTranslation } from "react-i18next";
function SelectPackPayment({ setShowPackages, handleHidePack }) {
  const [iPackages, setIPackages] = useState([]);
  const [showLocation, setShowLocation] = useState(false);
  const [showPay, setShowPay] = useState(false);
  const { t: translate } = useTranslation();
  const [category, setCategory] = useState(translate("eckpk"));
  const [isShown, setIsShown] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [totalPrice, setTotalPrice] = useState();
  const [showOptions, setShowOptions] = useState(false);
  const [newCustomer, setNewCustomer] = useState(false);
  const fileInputRef = useRef(null);
  const [imageUrl, setImageUrl] = useState("");
  const [isRequiredTextHidden, setIsRequiredTextHidden] = useState(false);
  const [isSubmitButtonHidden, setIsSubmitButtonHidden] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [needRouter, setNeedRouter] = useState(false);

  const [selectedLocation, setSelectedLocation] = useState("");

  const packagesCollectionRef = collection(db, "internetPack");

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const handleGoLocation = () => {
    const locationUrls = {
      "Ünalmar Market - Haspolat": "https://maps.app.goo.gl/HyrXugvmAxPepwWT9",
      "Uzmar Market - Alayköy": "https://maps.app.goo.gl/gfvjJ7zDiLW6kJF5A",
      "Tom & Bony Market - Lefkoşa":
        "https://maps.app.goo.gl/8Mp6NQvVWYs7xBCK7",
      "Servet Karadağ Barber Shop - Lefkoşa":
        "https://maps.app.goo.gl/1ptWRCuAnfs6Fw616",
      "Mineraliköy Kooperatifi - Lefkoşa":
        "https://maps.app.goo.gl/o1hb47qKw4tSkH9L7",
      "AlemNet Merkez ofis - Dereboyu":
        "https://maps.app.goo.gl/D6fziJU617AoEeXHA",
      "Alemdar Network Merkez ofis - Lefkoşa":
        "https://maps.app.goo.gl/USjwDhPEh19EVDWU7",
    };

    if (locationUrls[selectedLocation]) {
      window.open(locationUrls[selectedLocation], "_blank");
    } else {
      console.error("Invalid location selected");
    }
  };

  useEffect(() => {
    const getPackages = async () => {
      try {
        // Check local storage for saved data and timestamp
        const savedData = JSON.parse(localStorage.getItem("packagesData"));
        const savedTimestamp = localStorage.getItem("packagesTimestamp");

        // Check if data is older than one week (7 days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
        const oneWeek = 7 * 24 * 60 * 60 * 1000;
        const isDataExpired =
          !savedTimestamp ||
          Date.now() - new Date(savedTimestamp).getTime() > oneWeek;

        if (savedData && !isDataExpired) {
          // Use saved data if it's still valid
          setIPackages(savedData);
        } else {
          // Fetch new data from API
          const querySnapshot = await getDocs(packagesCollectionRef);
          const data = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          // Save the new data and timestamp in local storage
          localStorage.setItem("packagesData", JSON.stringify(data));
          localStorage.setItem("packagesTimestamp", new Date().toISOString());

          // Update state with the new data
          setIPackages(data);
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    getPackages();
  }, []);

  const hidePack = (plan) => {
    setFirstName();
    setLastName();
    setCountry();
    setEmail();
    setAddress();
    setPhoneNumber();
    setNeedRouter();
    setNeedRouter();
    setTotalPrice();
    setSelectedPlan(plan);
    setIsShown(!isShown);
    setShowOptions(false);
    setNewCustomer(false);
    handleHidePack();
    setShowPay(false);
  };
  const handleFilter = (value) => {
    setCategory(value);
  };

  const handlePackSelect = (price) => {
    setTotalPrice(price);
    setShowOptions(true);

    const packageData = {
      speed: `${selectedPlan.package_name} Mbps`,
      price: price,
      type: selectedPlan.type,
    };

    localStorage.setItem("selected_package", JSON.stringify(packageData));
    setShowPackages(false);
  };
  const handleNewCustomer = () => {
    setNewCustomer(true);
  };
  const handleFileUpload = () => {
    const file = fileInputRef.current.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImageUrl(imageUrl);
      setIsRequiredTextHidden(true);
      setIsSubmitButtonHidden(false);
    } else {
      setImageUrl("");
      setIsRequiredTextHidden(false);
      setIsSubmitButtonHidden(true);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const fireSubmit = async (event) => {
    event.preventDefault();
    if (!email) {
      alert("Please enter a valid email.");
      return;
    } else if (!phoneNumber) {
      alert("please enter phone number");
      return;
    }

    const storageRef = ref(imageDb, `id-cards/${imgUniqueId()}`);
    try {
      // 'file' comes from the Blob or File API
      const snapshot = await uploadBytes(
        storageRef,
        fileInputRef.current.files[0]
      );
      const imgUrl = await getDownloadURL(snapshot.ref);
      const api_base_url = process.env.REACT_APP_API_URL;
      await axios.post(`${api_base_url}/internetCustomers`, {
        customerFirstName: firstName,
        customerLastName: lastName,
        customerCountry: country,
        customerEmail: email,
        customerAddress: address,
        customerPhoneNumber: phoneNumber,
        customerNeedRouter: JSON.stringify(needRouter || false),
        nationalId: imgUrl,
        packageDetails:
          selectedPlan.package_name +
          " MB" +
          ", type:" +
          selectedPlan.package_type,
        totalPrice: totalPrice,
      });

      alert("your request was successfull");
      setTimeout(() => {
        window.location.reload();
      }, 3500);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const showCashPay = () => {
    setShowLocation(true);
  };
  const goPayPage = () => {
    handleHidePay();
    window.location.href = "/payment";
  };
  const showBankAcc = () => {
    window.location.href = "/bank_accounts";
  };
  const handleHidePay = () => {
    setShowPay(!showPay);
    if (showPay === false) {
      setShowLocation(false);
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <section
          id="pop-up-screen"
          style={{
            zIndex: 9,
            backdropFilter: "blur(3px) saturate(180%)",
            backgroundColor: "#ffffff1a",
          }}
          className={`services-section overflow-y-scroll spad cops w-[100%] flex felx-col items-center justify-center fixed top-0 h-[100vh] ${
            isShown ? "" : "none"
          }`}
        >
          <div className="card-container w-4/5 md:w-3/4 lg:w-2/4 overflow-y-scroll mt-[5rem] md:mt-[2px]">
            <div className="card w-full px-2">
              <button
                type="button"
                onClick={hidePack}
                className="dismiss w-full flex flex-col items-end justify-center "
              >
                <h1 className="flex flex-col items-center justify-center rounded-[7px] font-bold text-md border-2 border-r[#D1D5DB] w-[30px] h-[30px] mt-2 py-2 px-3">
                  x
                </h1>
              </button>
              {!newCustomer ? (
                <>
                  {!showPay ? (
                    <div
                      id="pack_card"
                      className="header flex flex-col items-center justify-center w-full"
                    >
                      <div className="content w-full flex flex-col items-center justify-center">
                        <span
                          className="text-[#4c57d6] text-[1rem]"
                          style={{
                            fontWeight: "600",
                            lineHeight: "1.5rem",
                            textTransform: "uppercase",
                          }}
                          id="package_name"
                        >
                          {selectedPlan.type} - {selectedPlan.package_name} Mbit
                        </span>
                        <p
                          className="message text-center"
                          data-translate="get_mst"
                        >
                          {translate("get_mst")} <br />
                          {translate("get_mst_2")} <br />
                          {translate("get_mst_3")}
                        </p>
                      </div>
                      <div className="w-full h-fit flex flex-col items-center justify-center gap-2 py-2 md:px-2 lg:px-4">
                        <div
                          className="w-full flex flex-row items-center justify-between border border-2 p-2 rounded-md cursor-pointer"
                          onClick={() =>
                            handlePackSelect(selectedPlan.price_one)
                          }
                        >
                          <h2 data-translate="mnth_1">{translate("mnth_1")}</h2>
                          <h1>
                            <span id="price_1">{selectedPlan.price_one}</span>{" "}
                            TL
                          </h1>
                        </div>
                        <div
                          className="w-full flex flex-row items-center justify-between border border-2 p-2 rounded-md cursor-pointer"
                          onClick={() =>
                            handlePackSelect(selectedPlan.price_three)
                          }
                        >
                          <h2 data-translate="mnth_3">{translate("mnth_3")}</h2>
                          <h1>
                            <span id="price_3">{selectedPlan.price_three}</span>{" "}
                            TL
                          </h1>
                        </div>
                        <div
                          className="w-full flex flex-row items-center justify-between border border-2 p-2 rounded-md cursor-pointer"
                          onClick={() =>
                            handlePackSelect(selectedPlan.price_six)
                          }
                        >
                          <h2 data-translate="mnth_6">{translate("mnth_6")}</h2>
                          <h1>
                            <span id="price_6">{selectedPlan.price_six}</span>{" "}
                            TL
                          </h1>
                        </div>
                        <div
                          className="w-full flex flex-row items-center justify-between border border-2 p-2 rounded-md cursor-pointer"
                          onClick={() =>
                            handlePackSelect(selectedPlan.price_twelve)
                          }
                        >
                          <h2 data-translate="mnth_12">
                            {translate("mnth_12")}
                          </h2>
                          <h1>
                            <span id="price_12">
                              {selectedPlan.price_twelve}
                            </span>{" "}
                            TL
                          </h1>
                        </div>
                      </div>
                      <div className="actions w-full py-[0.75rem] px-[1rem]">
                        <div
                          className="track mb-2"
                          style={{
                            display: "inline-flex",
                            marginTop: "0.75rem",
                            padding: "0.5rem 1rem",
                            color: "#242525",
                            fontSize: "1rem",
                            lineHeight: "1.5rem",
                            fontWeight: "500",
                            justifyContent: "center",
                            width: "100%",
                            borderRadius: "0.375rem",
                            border: "1px solid #D1D5DB",
                            backgroundColor: "#fff",
                            boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
                          }}
                        >
                          <h1 className="text-black font-semibold">
                            Total :{" "}
                            <span id="total" className="">
                              {totalPrice}
                            </span>{" "}
                            TL
                          </h1>
                        </div>
                        {showOptions && (
                          <div
                            onClick={handleHidePay}
                            className="w-full flex flex-row items-center justify-between gap-4"
                          >
                            <div
                              style={{
                                display: "inline-flex",
                                padding: "0.5rem 1rem",
                                fontSize: "1rem",
                                lineHeight: "1.5rem",
                                fontWeight: "500",
                                justifyContent: "center",
                                width: "100%",
                                borderRadius: "0.375rem",
                                border: "none",
                                backgroundColor: "#120851",
                                boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
                              }}
                              className="history text-white"
                              data-translate="buy_pkg"
                              to="/payment"
                              previewlistener="true"
                            >
                              {translate("buy_pkg")}
                            </div>
                            <div
                              style={{
                                display: "inline-flex",
                                padding: "0.5rem 1rem",
                                fontSize: "1rem",
                                lineHeight: "1.5rem",
                                fontWeight: "500",
                                justifyContent: "center",
                                width: "100%",
                                borderRadius: "0.375rem",
                                border: "none",
                                backgroundColor: "#120851",
                                boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
                              }}
                              data-translate="nw_custm"
                              onClick={handleNewCustomer}
                              className="history  text-white"
                            >
                              {translate("nw_custm")}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="header">
                      <div className="content">
                        <ul className="message">
                          <div className="w-full flex flex-col items-center justify-center wrap px-4">
                            <button
                              data-translate="cash_pay"
                              onClick={showCashPay}
                              className="mt-3 px-3 py-2 bg-slate-600 text-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-[13.22rem] lg:w-[54%] rounded-md sm:text-sm focus:ring-1"
                            >
                              {translate("cash_pay")}
                            </button>
                            {showLocation && (
                              <select
                                name="locations"
                                id="locations"
                                className=" mt-3 px-3 py-2 border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-[13.22rem] lg:w-[54%] rounded-md sm:text-sm focus:ring-1"
                                onChange={handleLocationChange}
                                value={selectedLocation}
                              >
                                <option value="Ünalmar Market - Haspolat">
                                  Ünalmar Market - Haspolat
                                </option>
                                <option value="Uzmar Market - Alayköy">
                                  Uzmar Market - Alayköy
                                </option>
                                <option value="Tom & Bony Market - Lefkoşa">
                                  Tom & Bony Market - Lefkoşa
                                </option>
                                <option value="Servet Karadağ Barber Shop - Lefkoşa">
                                  Servet Karadağ Barber Shop - Lefkoşa
                                </option>
                                <option value="Mineraliköy Kooperatifi - Lefkoşa">
                                  Mineraliköy Kooperatifi - Lefkoşa
                                </option>
                                <option value="AlemNet Merkez ofis - Dereboyu">
                                  AlemNet Merkez ofis - Dereboyu
                                </option>
                                <option value="Alemdar Network Merkez ofis - Lefkoşa">
                                  Alemdar Network Merkez ofis - Lefkoşa
                                </option>
                              </select>
                            )}
                          </div>
                          <div className="w-full flex flex-col items-center justify-center wrap px-4">
                            <button
                              onClick={goPayPage}
                              className=" mt-3 px-3 py-2 bg-blue-600 text-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-[13.22rem] lg:w-[54%] rounded-md sm:text-sm focus:ring-1"
                            >
                              {translate("online_payment")}
                            </button>
                          </div>
                          <div className="w-full flex flex-col items-center justify-center wrap px-4">
                            <button
                              data-translate="go_location"
                              onClick={handleGoLocation}
                              className=" mt-3 px-3 py-2 bg-green-600 text-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-[13.22rem] lg:w-[54%] rounded-md sm:text-sm focus:ring-1"
                            >
                              {translate("go_location")}
                            </button>
                          </div>
                          <div className="w-full flex flex-col items-center justify-center wrap px-4">
                            <button
                              data-translate="bank_trans_btn"
                              onClick={showBankAcc}
                              className="my-3 px-3 py-2 bg-slate-600 text-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-[13.22rem] lg:w-[54%] rounded-md sm:text-sm focus:ring-1"
                            >
                              {translate("bank_trans_btn")}
                            </button>
                          </div>
                        </ul>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div id="newCustomCard" className="header">
                  <div className="content flex flex-col items-center justify-center">
                    <span className="title" id="package_name"></span>
                    <p
                      className="message font-medium text-slate-700 text-sm"
                      data-translate="rgistr"
                    >
                      {translate("rgistr")}
                    </p>
                    <form
                      className="flex flex-col items-start justify-start w-full overflow-scroll gap-[.75rem]"
                      enctype="multipart/form-data"
                      id="registration_customer"
                      method="post"
                      name="registration_customer"
                    >
                      <div className="w-full flex flex-col md:flex-row gap-2 items-center justify-center wrap">
                        <div className="md:w-[50%] w-full px-3 md:px-0 ">
                          <label className="block">
                            <span
                              className="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700"
                              data-translate="f_name"
                            >
                              {translate("f_name")}
                            </span>
                            <input
                              type="text"
                              name="f_name"
                              value={firstName}
                              onChange={(event) => {
                                setFirstName(event.target.value);
                              }}
                              className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                              placeholder="Enter your First Name"
                            />
                          </label>
                        </div>
                        <div className="md:w-[50%] w-full px-3 md:px-0 ">
                          <label className="block">
                            <span
                              className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700"
                              data-translate="l_name"
                            >
                              {translate("l_name")}
                            </span>
                            <input
                              type="text"
                              name="l_name"
                              value={lastName}
                              onChange={(event) => {
                                setLastName(event.target.value);
                              }}
                              className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                              required=""
                              placeholder="Enter your Last Name"
                            />
                          </label>
                        </div>
                      </div>
                      <div className="w-full flex flex-col md:flex-row gap-2 items-center justify-center wrap">
                        <div className="md:w-[50%] w-full px-3 md:px-0 ">
                          <label className="block">
                            <span
                              className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700"
                              data-translate="ntnlty"
                            >
                              {translate("ntnlty")}
                            </span>
                            <select
                              name="country"
                              className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                              aria-invalid="false"
                              required=""
                              value={country}
                              onChange={(event) => {
                                setCountry(event.target.value);
                              }}
                            >
                              <option
                                disabled=""
                                selected=""
                                hidden=""
                                value=""
                              >
                                ---
                              </option>
                              <option value="United States">
                                United States
                              </option>
                              <option value="Canada">Canada</option>
                              <option value="Mexico">Mexico</option>
                              <option value="United Kingdom">
                                United Kingdom
                              </option>
                              <option value="Poland">Poland</option>
                              <option
                                value="-----"
                                disabled=""
                                selected=""
                                hidden=""
                              >
                                -----
                              </option>
                              <option value="Afghanistan">Afghanistan</option>
                              <option value="Albania">Albania</option>
                              <option value="Algeria">Algeria</option>
                              <option value="American Samoa">
                                American Samoa
                              </option>
                              <option value="Andorra">Andorra</option>
                              <option value="Angola">Angola</option>
                              <option value="Anguilla">Anguilla</option>
                              <option value="Antigua and Barbuda">
                                Antigua and Barbuda
                              </option>
                              <option value="Argentina">Argentina</option>
                              <option value="Armenia">Armenia</option>
                              <option value="Armenia">Armenia</option>
                              <option value="Aruba">Aruba</option>
                              <option value="Australia">Australia</option>
                              <option value="Austria">Austria</option>
                              <option value="Azerbaijan">Azerbaijan</option>
                              <option value="Azerbaijan">Azerbaijan</option>
                              <option value="Bahamas">Bahamas</option>
                              <option value="Bahrain">Bahrain</option>
                              <option value="Bangladesh">Bangladesh</option>
                              <option value="Barbados">Barbados</option>
                              <option value="Belarus">Belarus</option>
                              <option value="Belgium">Belgium</option>
                              <option value="Belize">Belize</option>
                              <option value="Benin">Benin</option>
                              <option value="Bermuda">Bermuda</option>
                              <option value="Bhutan">Bhutan</option>
                              <option value="Bolivia">Bolivia</option>
                              <option value="Bonaire">Bonaire</option>
                              <option value="Bosnia and Herzegovina">
                                Bosnia and Herzegovina
                              </option>
                              <option value="Botswana">Botswana</option>
                              <option value="Bouvet Island (Bouvetoya)">
                                Bouvet Island (Bouvetoya)
                              </option>
                              <option value="Brazil">Brazil</option>
                              <option value="British Indian Ocean Territory (Chagos Archipelago)">
                                British Indian Ocean Territory (Chagos
                                Archipelago)
                              </option>
                              <option value="British Virgin Islands">
                                British Virgin Islands
                              </option>
                              <option value="Brunei Darussalam">
                                Brunei Darussalam
                              </option>
                              <option value="Bulgaria">Bulgaria</option>
                              <option value="Burkina Faso">Burkina Faso</option>
                              <option value="Burundi">Burundi</option>
                              <option value="Cambodia">Cambodia</option>
                              <option value="Cameroon">Cameroon</option>
                              <option value="Cape Verde">Cape Verde</option>
                              <option value="Cayman Islands">
                                Cayman Islands
                              </option>
                              <option value="Central African Republic">
                                Central African Republic
                              </option>
                              <option value="Chad">Chad</option>
                              <option value="Chile">Chile</option>
                              <option value="China">China</option>
                              <option value="Christmas Island">
                                Christmas Island
                              </option>
                              <option value="Cocos (Keeling) Islands">
                                Cocos (Keeling) Islands
                              </option>
                              <option value="Colombia">Colombia</option>
                              <option value="Comoros">Comoros</option>
                              <option value="Congo">Congo</option>
                              <option value="Congo">Congo</option>
                              <option value="Cook Islands">Cook Islands</option>
                              <option value="Costa Rica">Costa Rica</option>
                              <option value="Cote d'Ivoire">
                                Cote d'Ivoire
                              </option>
                              <option value="Croatia">Croatia</option>
                              <option value="Cuba">Cuba</option>
                              <option value="Curaçao">Curaçao</option>
                              <option value="Cyprus">Cyprus</option>
                              <option value="Cyprus">Cyprus</option>
                              <option value="Czech Republic">
                                Czech Republic
                              </option>
                              <option value="Denmark">Denmark</option>
                              <option value="Djibouti">Djibouti</option>
                              <option value="Dominica">Dominica</option>
                              <option value="Dominican Republic">
                                Dominican Republic
                              </option>
                              <option value="Ecuador">Ecuador</option>
                              <option value="Egypt">Egypt</option>
                              <option value="El Salvador">El Salvador</option>
                              <option value="Equatorial Guinea">
                                Equatorial Guinea
                              </option>
                              <option value="Eritrea">Eritrea</option>
                              <option value="Estonia">Estonia</option>
                              <option value="Ethiopia">Ethiopia</option>
                              <option value="Falkland Islands (Malvinas)">
                                Falkland Islands (Malvinas)
                              </option>
                              <option value="Faroe Islands">
                                Faroe Islands
                              </option>
                              <option value="Fiji">Fiji</option>
                              <option value="Finland">Finland</option>
                              <option value="France">France</option>
                              <option value="French Guiana">
                                French Guiana
                              </option>
                              <option value="French Polynesia">
                                French Polynesia
                              </option>
                              <option value="French Southern Territories">
                                French Southern Territories
                              </option>
                              <option value="Gabon">Gabon</option>
                              <option value="Gambia">Gambia</option>
                              <option value="Georgia">Georgia</option>
                              <option value="Germany">Germany</option>
                              <option value="Ghana">Ghana</option>
                              <option value="Gibraltar">Gibraltar</option>
                              <option value="Greece">Greece</option>
                              <option value="Greenland">Greenland</option>
                              <option value="Grenada">Grenada</option>
                              <option value="Guadeloupe">Guadeloupe</option>
                              <option value="Guam">Guam</option>
                              <option value="Guatemala">Guatemala</option>
                              <option value="Guernsey">Guernsey</option>
                              <option value="Guinea">Guinea</option>
                              <option value="Guinea-Bissau">
                                Guinea-Bissau
                              </option>
                              <option value="Guyana">Guyana</option>
                              <option value="Haiti">Haiti</option>
                              <option value="Heard Island and McDonald Islands">
                                Heard Island and McDonald Islands
                              </option>
                              <option value="Holy See (Vatican City State)">
                                Holy See (Vatican City State)
                              </option>
                              <option value="Honduras">Honduras</option>
                              <option value="Hong Kong">Hong Kong</option>
                              <option value="Hungary">Hungary</option>
                              <option value="Iceland">Iceland</option>
                              <option value="India">India</option>
                              <option value="Indonesia">Indonesia</option>
                              <option value="Iran">Iran</option>
                              <option value="Iraq">Iraq</option>
                              <option value="Ireland">Ireland</option>
                              <option value="Isle of Man">Isle of Man</option>
                              <option value="Israel">Israel</option>
                              <option value="Italy">Italy</option>
                              <option value="Jamaica">Jamaica</option>
                              <option value="Japan">Japan</option>
                              <option value="Jersey">Jersey</option>
                              <option value="Jordan">Jordan</option>
                              <option value="Kazakhstan">Kazakhstan</option>
                              <option value="Kazakhstan">Kazakhstan</option>
                              <option value="Kenya">Kenya</option>
                              <option value="Kiribati">Kiribati</option>
                              <option value="Korea">Korea</option>
                              <option value="Korea">Korea</option>
                              <option value="Kuwait">Kuwait</option>
                              <option value="Kyrgyz Republic">
                                Kyrgyz Republic
                              </option>
                              <option value="Lao People's Democratic Republic">
                                Lao People's Democratic Republic
                              </option>
                              <option value="Latvia">Latvia</option>
                              <option value="Lebanon">Lebanon</option>
                              <option value="Lesotho">Lesotho</option>
                              <option value="Liberia">Liberia</option>
                              <option value="Libyan Arab Jamahiriya">
                                Libyan Arab Jamahiriya
                              </option>
                              <option value="Liechtenstein">
                                Liechtenstein
                              </option>
                              <option value="Lithuania">Lithuania</option>
                              <option value="Luxembourg">Luxembourg</option>
                              <option value="Macao">Macao</option>
                              <option value="Macedonia">Macedonia</option>
                              <option value="Madagascar">Madagascar</option>
                              <option value="Malawi">Malawi</option>
                              <option value="Malaysia">Malaysia</option>
                              <option value="Maldives">Maldives</option>
                              <option value="Mali">Mali</option>
                              <option value="Malta">Malta</option>
                              <option value="Marshall Islands">
                                Marshall Islands
                              </option>
                              <option value="Martinique">Martinique</option>
                              <option value="Mauritania">Mauritania</option>
                              <option value="Mauritius">Mauritius</option>
                              <option value="Mayotte">Mayotte</option>
                              <option value="Micronesia">Micronesia</option>
                              <option value="Moldova">Moldova</option>
                              <option value="Monaco">Monaco</option>
                              <option value="Mongolia">Mongolia</option>
                              <option value="Montenegro">Montenegro</option>
                              <option value="Montserrat">Montserrat</option>
                              <option value="Morocco">Morocco</option>
                              <option value="Mozambique">Mozambique</option>
                              <option value="Myanmar">Myanmar</option>
                              <option value="Namibia">Namibia</option>
                              <option value="Nauru">Nauru</option>
                              <option value="Nepal">Nepal</option>
                              <option value="Netherlands">Netherlands</option>
                              <option value="Netherlands Antilles">
                                Netherlands Antilles
                              </option>
                              <option value="New Caledonia">
                                New Caledonia
                              </option>
                              <option value="New Zealand">New Zealand</option>
                              <option value="Nicaragua">Nicaragua</option>
                              <option value="Niger">Niger</option>
                              <option value="Nigeria">Nigeria</option>
                              <option value="Niue">Niue</option>
                              <option value="Norfolk Island">
                                Norfolk Island
                              </option>
                              <option value="Northern Mariana Islands">
                                Northern Mariana Islands
                              </option>
                              <option value="Norway">Norway</option>
                              <option value="Oman">Oman</option>
                              <option value="Pakistan">Pakistan</option>
                              <option value="Palau">Palau</option>
                              <option value="Palestinian Territory">
                                Palestinian Territory
                              </option>
                              <option value="Panama">Panama</option>
                              <option value="Papua New Guinea">
                                Papua New Guinea
                              </option>
                              <option value="Paraguay">Paraguay</option>
                              <option value="Peru">Peru</option>
                              <option value="Philippines">Philippines</option>
                              <option value="Pitcairn Islands">
                                Pitcairn Islands
                              </option>
                              <option value="Poland">Poland</option>
                              <option value="Portugal">Portugal</option>
                              <option value="Puerto Rico">Puerto Rico</option>
                              <option value="Qatar">Qatar</option>
                              <option value="Reunion">Reunion</option>
                              <option value="Romania">Romania</option>
                              <option value="Russian Federation">
                                Russian Federation
                              </option>
                              <option value="Rwanda">Rwanda</option>
                              <option value="Saint Barthelemy">
                                Saint Barthelemy
                              </option>
                              <option value="Saint Helena">Saint Helena</option>
                              <option value="Saint Kitts and Nevis">
                                Saint Kitts and Nevis
                              </option>
                              <option value="Saint Lucia">Saint Lucia</option>
                              <option value="Saint Martin">Saint Martin</option>
                              <option value="Saint Pierre and Miquelon">
                                Saint Pierre and Miquelon
                              </option>
                              <option value="Saint Vincent and the Grenadines">
                                Saint Vincent and the Grenadines
                              </option>
                              <option value="Samoa">Samoa</option>
                              <option value="San Marino">San Marino</option>
                              <option value="Sao Tome and Principe">
                                Sao Tome and Principe
                              </option>
                              <option value="Saudi Arabia">Saudi Arabia</option>
                              <option value="Senegal">Senegal</option>
                              <option value="Serbia">Serbia</option>
                              <option value="Seychelles">Seychelles</option>
                              <option value="Sierra Leone">Sierra Leone</option>
                              <option value="Singapore">Singapore</option>
                              <option value="Sint Maarten (Netherlands)">
                                Sint Maarten (Netherlands)
                              </option>
                              <option value="Slovakia (Slovak Republic)">
                                Slovakia (Slovak Republic)
                              </option>
                              <option value="Slovenia">Slovenia</option>
                              <option value="Solomon Islands">
                                Solomon Islands
                              </option>
                              <option value="Somalia">Somalia</option>
                              <option value="South Africa">South Africa</option>
                              <option value="South Georgia &amp; S. Sandwich Islands">
                                South Georgia &amp; S. Sandwich Islands
                              </option>
                              <option value="Spain">Spain</option>
                              <option value="Sri Lanka">Sri Lanka</option>
                              <option value="Sudan">Sudan</option>
                              <option value="Suriname">Suriname</option>
                              <option value="Svalbard &amp; Jan Mayen Islands">
                                Svalbard &amp; Jan Mayen Islands
                              </option>
                              <option value="Swaziland">Swaziland</option>
                              <option value="Sweden">Sweden</option>
                              <option value="Switzerland">Switzerland</option>
                              <option value="Syrian Arab Republic">
                                Syrian Arab Republic
                              </option>
                              <option value="Taiwan">Taiwan</option>
                              <option value="Tajikistan">Tajikistan</option>
                              <option value="Tanzania">Tanzania</option>
                              <option value="Thailand">Thailand</option>
                              <option value="Timor-Leste">Timor-Leste</option>
                              <option value="Togo">Togo</option>
                              <option value="Tokelau">Tokelau</option>
                              <option value="Tonga">Tonga</option>
                              <option value="Trinidad and Tobago">
                                Trinidad and Tobago
                              </option>
                              <option value="Tunisia">Tunisia</option>
                              <option value="Turkey">Turkey</option>
                              <option value="Turkey">Turkey</option>
                              <option value="Turkmenistan">Turkmenistan</option>
                              <option value="Turks and Caicos Islands">
                                Turks and Caicos Islands
                              </option>
                              <option value="Tuvalu">Tuvalu</option>
                              <option value="U.S. Virgin Islands">
                                U.S. Virgin Islands
                              </option>
                              <option value="U.S. Minor Outlying Islands">
                                U.S. Minor Outlying Islands
                              </option>
                              <option value="Uganda">Uganda</option>
                              <option value="Ukraine">Ukraine</option>
                              <option value="United Arab Emirates">
                                United Arab Emirates
                              </option>
                              <option value="Uruguay">Uruguay</option>
                              <option value="Uzbekistan">Uzbekistan</option>
                              <option value="Vanuatu">Vanuatu</option>
                              <option value="Venezuela">Venezuela</option>
                              <option value="Vietnam">Vietnam</option>
                              <option value="Wallis and Futuna">
                                Wallis and Futuna
                              </option>
                              <option value="Western Sahara">
                                Western Sahara
                              </option>
                              <option value="Yemen">Yemen</option>
                              <option value="Zambia">Zambia</option>
                              <option value="Zimbabwe">Zimbabwe</option>
                            </select>
                          </label>
                        </div>
                        <div className="md:w-[50%] w-full px-3 md:px-0 ">
                          <label className="block">
                            <span
                              className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700"
                              data-translate="drssa"
                            >
                              {translate("drssa")}
                            </span>
                            <input
                              type="text"
                              name="address"
                              value={address}
                              onChange={(event) => {
                                setAddress(event.target.value);
                              }}
                              className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                              placeholder="Enter your address"
                              required=""
                            />
                          </label>
                        </div>
                      </div>
                      <div className="w-full flex flex-col md:flex-row gap-2 items-center justify-center wrap">
                        <div className="md:w-[50%] w-full px-3 md:px-0 ">
                          <label className="block">
                            <span
                              className="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700"
                              data-translate="ml"
                            >
                              {translate("ml")}
                            </span>
                            <input
                              type="text"
                              name="email_n"
                              value={email}
                              onChange={(event) => {
                                setEmail(event.target.value);
                              }}
                              className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                              placeholder="Enter your Email"
                            />
                          </label>
                        </div>
                        <div className="md:w-[50%] w-full px-3 md:px-0 ">
                          <label className="block">
                            <span
                              className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700"
                              data-translate="phn"
                            >
                              {translate("phn")}
                            </span>
                            <input
                              type="tel"
                              name="phone"
                              value={phoneNumber}
                              onChange={(event) => {
                                setPhoneNumber(event.target.value);
                              }}
                              className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                              placeholder="Phone number"
                              required=""
                            />
                          </label>
                        </div>
                      </div>
                      <div className="w-full flex flex-row gap-2 items-center justify-center wrap">
                        <div className="lg:w-[50%] w-full px-3 md:px-0">
                          <label className="flex flex-row items-baseline justify-start gap-2">
                            <input
                              id="router"
                              className="peer/draft"
                              type="checkbox"
                              name="need_router"
                              value={needRouter}
                              onChange={() => {
                                setNeedRouter(!needRouter);
                              }}
                            />
                            <h2
                              for="draft"
                              className="peer-checked/draft:text-sky-500 mb-1 text-[14px] font-normal"
                              data-translate="nd_rtr"
                            >
                              {translate("nd_rtr")}
                            </h2>
                          </label>
                        </div>
                        <div className="md:w-[50%] w-full px-3 md:px-0  text-white">
                          <label className="block">
                            <span className="block text-sm font-medium">
                              Pack
                            </span>
                            <input
                              type="text"
                              id="package_name_buy"
                              name="package_name"
                              readOnly
                              value={selectedPlan}
                              className="mt-1 px-3 py-2 bg-white block w-full rounded-md sm:text-sm"
                              required=""
                            />
                          </label>
                        </div>
                      </div>
                      <div className="w-full flex flex-row gap-2 items-center justify-center wrap">
                        <div className="lg:w-[85%] w-full px-3 md:px-0">
                          <div className="flex flex-col items-center justify-start w-full">
                            <div className="text-center flex items-center">
                              {imageUrl && (
                                <img
                                  src={imageUrl}
                                  id="profileImage"
                                  alt="profileImage"
                                  style={{ objectFit: "cover" }}
                                  className="w-[13.22rem] h-[8rem] m-auto rounded-md shadow"
                                />
                              )}
                            </div>
                            <input
                              name="std_id"
                              type="file"
                              accept="image/*"
                              id="fileInput"
                              onChange={handleFileUpload}
                              ref={fileInputRef}
                              className="none"
                              style={{
                                color: "transparent",
                                cursor: "pointer",
                              }}
                            />
                            <button
                              type="button"
                              className="mt-1 px-3 py-2 bg-slate-600 text-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-[13.22rem] rounded-md sm:text-sm focus:ring-1"
                              onClick={handleButtonClick}
                            >
                              Upload your ID
                            </button>
                            <h1
                              className={`block text-sm font-medium text-slate-700 ${
                                isRequiredTextHidden ? "hidden" : ""
                              }`}
                              id="requied_text"
                              data-translate="required_id"
                            >
                              {translate("required_id")}
                            </h1>
                            <button
                              onClick={fireSubmit}
                              type="button"
                              className={`mt-1 px-3 py-2 bg-slate-600 text-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-[13.22rem] rounded-md sm:text-sm focus:ring-1 ${
                                isSubmitButtonHidden ? "hidden" : ""
                              }`}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="w-full flex flex-col items-center justify-center wrap px-4">
                        <button
                          id="submit_btn"
                          data-translate="sbmt_txt"
                          type="submit"
                          onClick={fireSubmit}
                          className="hidden mt-3 px-3 py-2 bg-slate-600 text-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-[13.22rem] lg:w-[54%] rounded-md sm:text-sm focus:ring-1"
                        >
                          {translate("sbmt_txt")}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
      <section id="packages" className="pricing-section spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-7">
              <div className="section-title normal-title">
                <h3
                  data-translate="pln_p"
                  style={{ textTransform: "none !important" }}
                >
                  {translate("pln_p")}
                </h3>
              </div>
            </div>
            <div className="col-lg-5 col-md-5">
              <div className="pricing__swipe-btn">
                {[translate("eckpk"), translate("prpk")].map((type) => (
                  <label
                    key={type}
                    onClick={() => handleFilter(type)}
                    htmlFor="inputId"
                    style={{ textTransform: "none" }}
                    className={category === type ? "active" : ""}
                  >
                    {type}
                  </label>
                ))}
              </div>
            </div>
          </div>
          {category === translate("eckpk") ? (
            <div className="row monthly__plans active">
              {iPackages
                .filter((item) => item.type === "eco")
                .sort(
                  (a, b) => parseInt(a.package_name) - parseInt(b.package_name)
                )
                .map((item, index) => (
                  <div key={index} className="col-lg-3 col-md-6 col-sm-6">
                    <div className="pricing__item">
                      <h3>
                        {item.package_name} {translate("mbs_1")}
                        <span>{translate("mbs_2")}</span>
                      </h3>
                      <ul>
                        <li className="price_tag">
                          <span className="priceOne">{item.price_one} </span> TL
                        </li>
                        <li data-translate="pay_3_1">{translate("pay_3_1")}</li>
                        <li data-translate="pay_6_2">{translate("pay_6_2")}</li>
                        <li data-translate="pay_12_3">
                          {translate("pay_12_3")}
                        </li>
                      </ul>
                      <div
                        onClick={() => hidePack(item)}
                        className="primary-btn"
                        data-translate="chze"
                      >
                        {translate("chze")}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <div className="row yearly__plans active">
              {iPackages
                .filter((item) => item.type === "pro")
                .sort(
                  (a, b) => parseInt(a.package_name) - parseInt(b.package_name)
                )
                .map((item, index) => (
                  <div key={index} className="col-lg-3 col-md-6 col-sm-6">
                    <div className="pricing__item">
                      <h3>
                        {item.package_name} {translate("mbs_1")}
                        <span>{translate("mbs_2")}</span>
                      </h3>
                      <ul>
                        <li className="price_tag">
                          <span className="priceOne">{item.price_one} </span> TL{" "}
                        </li>
                        <li data-translate="pay_3_1">{translate("pay_3_1")}</li>
                        <li data-translate="pay_6_2">{translate("pay_6_2")}</li>
                        <li data-translate="pay_12_3">
                          {translate("pay_12_3")}
                        </li>
                      </ul>
                      <div
                        onClick={() => hidePack(item)}
                        className="primary-btn"
                        data-translate="chze"
                      >
                        {translate("chze")}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default SelectPackPayment;
