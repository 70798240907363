import React, { useState } from "react";
import { db } from "../firebase-config";
import { collection, addDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
function Faq() {
  const { t: translate } = useTranslation();
  const dataCollectionRef = collection(db, "alemnet-contact-us");
  const [username, setUsername] = useState();
  const [usermail, setUsermail] = useState();
  const [message, setMessage] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const fireSubmit = async (event) => {
    event.preventDefault();

    // Email validation
    if (!usermail) {
      alert("Please enter a valid email.");
      return;
    }

    // Send data to Firebase
    try {
      await addDoc(dataCollectionRef, {
        name: username,
        mail_field: usermail,
        message: message,
      });

      setIsSubmitted(true);

      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      // setSuccessMessage("Error, try again or reload !");
      console.error("Error submitting form:", error);
    }
  };
  return (
    <>
      <div className="mt-[5rem] md:mt-[6rem]">
        <div className="breadcrumb-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb__option">
                  <Link data-translate="home_txt" to="/" previewlistener="true">
                    <span className="fa fa-home"></span> {translate("home_txt")}
                  </Link>
                  <span data-translate="cmp_fq">{translate("cmp_fq")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="question-section spad">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title">
                  <h3 data-translate="hv_q">Have a question ?</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="question__accordin">
                  <div class="bg-white border border-gray-200 divide-y divide-gray-200 rounded-xl m-12">
                    <details class="p-6 group" open>
                      <summary class="flex items-center justify-between cursor-pointer">
                        <h5 class="text-lg font-medium text-gray-900">
                          Lorem ipsum dolor sit amet consectetur adipisicing?
                        </h5>

                        <span class="relative flex-shrink-0 ml-1.5 w-5 h-5">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="absolute inset-0 w-5 h-5 opacity-100 group-open:opacity-0"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>

                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="absolute inset-0 w-5 h-5 opacity-0 group-open:opacity-100"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </span>
                      </summary>

                      <p class="mt-4 leading-relaxed text-gray-700">
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Ab hic veritatis molestias culpa in, recusandae
                        laboriosam neque aliquid libero nesciunt voluptate dicta
                        quo officiis explicabo consequuntur distinctio corporis
                        earum similique!
                      </p>
                    </details>

                    <details class="p-6 group">
                      <summary class="flex items-center justify-between cursor-pointer">
                        <h5 class="text-lg font-medium text-gray-900">
                          Lorem ipsum dolor sit amet consectetur adipisicing?
                        </h5>

                        <span class="relative flex-shrink-0 ml-1.5 w-5 h-5">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="absolute inset-0 w-5 h-5 opacity-100 group-open:opacity-0"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>

                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="absolute inset-0 w-5 h-5 opacity-0 group-open:opacity-100"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </span>
                      </summary>

                      <p class="mt-4 leading-relaxed text-gray-700">
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Ab hic veritatis molestias culpa in, recusandae
                        laboriosam neque aliquid libero nesciunt voluptate dicta
                        quo officiis explicabo consequuntur distinctio corporis
                        earum similique!
                      </p>
                    </details>

                    <details class="p-6 group">
                      <summary class="flex items-center justify-between cursor-pointer">
                        <h5 class="text-lg font-medium text-gray-900">
                          Lorem ipsum dolor sit amet consectetur adipisicing?
                        </h5>

                        <span class="relative flex-shrink-0 ml-1.5 w-5 h-5">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="absolute inset-0 w-5 h-5 opacity-100 group-open:opacity-0"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>

                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="absolute inset-0 w-5 h-5 opacity-0 group-open:opacity-100"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </span>
                      </summary>

                      <p class="mt-4 leading-relaxed text-gray-700">
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Ab hic veritatis molestias culpa in, recusandae
                        laboriosam neque aliquid libero nesciunt voluptate dicta
                        quo officiis explicabo consequuntur distinctio corporis
                        earum similique!
                      </p>
                    </details>
                    <details class="p-6 group">
                      <summary class="flex items-center justify-between cursor-pointer">
                        <h5 class="text-lg font-medium text-gray-900">
                          Lorem ipsum dolor sit amet consectetur adipisicing?
                        </h5>

                        <span class="relative flex-shrink-0 ml-1.5 w-5 h-5">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="absolute inset-0 w-5 h-5 opacity-100 group-open:opacity-0"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>

                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="absolute inset-0 w-5 h-5 opacity-0 group-open:opacity-100"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </span>
                      </summary>

                      <p class="mt-4 leading-relaxed text-gray-700">
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Ab hic veritatis molestias culpa in, recusandae
                        laboriosam neque aliquid libero nesciunt voluptate dicta
                        quo officiis explicabo consequuntur distinctio corporis
                        earum similique!
                      </p>
                    </details>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <form
                  method="post"
                  id="wf-form-Styleguide-Form"
                  name="wf-form-Styleguide-Form"
                  class="question-form"
                >
                  <input
                    type="text"
                    name="fullName"
                    onChange={(event) => {
                      setUsername(event.target.value);
                    }}
                    value={username}
                    placeholder={translate("ent_full_name")}
                  />
                  <input
                    type="text"
                    name="email_e"
                    onChange={(event) => {
                      setUsermail(event.target.value);
                    }}
                    value={usermail}
                    placeholder={translate("ent_email")}
                  />
                  <textarea
                    name="message"
                    onChange={(event) => {
                      setMessage(event.target.value);
                    }}
                    placeholder={translate("ent_question")}
                  ></textarea>
                  <input
                    type="submit"
                    id="form-submit-contact"
                    value={translate("send_m")}
                    class="site-btn"
                    name="send_m"
                    onClick={fireSubmit}
                    style={{
                      color: "whitesmoke",
                      backgroundColor: "#4c57d6",
                    }}
                  ></input>
                </form>
                {isSubmitted && (
                  <div className="form-message-success w-form-done">
                    <div data-translate="frm_4">{translate("frm_4")}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Faq;
