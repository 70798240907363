import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./translations/en.json"; // Import translations
import trTranslation from "./translations/tr.json"; // Import translations
import ruTranslation from "./translations/ru.json"; // Import translations
// Import translations for other languages as needed

// console.log("lannggg: ", localStorage.getItem("lang"));
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation }, // Assign translations
    ru: { translation: ruTranslation }, // Assign translations
    tr: { translation: trTranslation }, // Assign translations
    // Add other languages similarly
  },
  lng: localStorage.getItem("lang") || "tr",
  fallbackLng: "tr",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
