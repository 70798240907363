import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";
function Header() {
  const { t: translate } = useTranslation();

  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [showPay, setShowPay] = useState(false);
  const [showLocation, setShowLocation] = useState(false);

  const [selectedLocation, setSelectedLocation] = useState("");

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const handleGoLocation = () => {
    const locationUrls = {
      "Ünalmar Market - Haspolat": "https://maps.app.goo.gl/HyrXugvmAxPepwWT9",
      "Uzmar Market - Alayköy": "https://maps.app.goo.gl/gfvjJ7zDiLW6kJF5A",
      "Tom & Bony Market - Lefkoşa":
        "https://maps.app.goo.gl/8Mp6NQvVWYs7xBCK7",
      "Servet Karadağ Barber Shop - Lefkoşa":
        "https://maps.app.goo.gl/1ptWRCuAnfs6Fw616",
      "Mineraliköy Kooperatifi - Lefkoşa":
        "https://maps.app.goo.gl/o1hb47qKw4tSkH9L7",
      "AlemNet Merkez ofis - Dereboyu":
        "https://maps.app.goo.gl/D6fziJU617AoEeXHA",
      "Alemdar Network Merkez ofis - Lefkoşa":
        "https://maps.app.goo.gl/USjwDhPEh19EVDWU7",
    };

    if (locationUrls[selectedLocation]) {
      window.open(locationUrls[selectedLocation], "_blank");
    } else {
      console.error("Invalid location selected");
    }
  };

  const lang = localStorage.getItem("lang") || "tr";
  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
      localStorage.setItem("lang", lang);
    }
  }, [lang]);
  function handleLanguageChange(lang) {
    localStorage.setItem("lang", lang);
  }
  let flagUrl;
  switch (lang) {
    case "en":
      flagUrl = "https://flagcdn.com/48x36/gb.png";
      break;
    case "tr":
      flagUrl = "https://flagcdn.com/48x36/tr.png";
      break;
    case "ru":
      flagUrl = "https://flagcdn.com/48x36/ru.png";
      break;
    default:
      flagUrl = "https://flagcdn.com/48x36/tr.png";
  }

  const handleHidePay = () => {
    setShowPay(!showPay);
    if (showPay === false) {
      setShowLocation(false);
    }
  };
  const showCashPay = () => {
    setShowLocation(true);
  };
  const goPayPage = () => {
    handleHidePay();
    window.location.href = "/payment";
  };
  const showBankAcc = () => {
    window.location.href = "/bank_accounts";
  };
  const toggleLangBtn = () => {
    setIsOpen(true);
    setTimeout(() => {
      setIsOpen(false);
    }, 2000);
  };
  const navLinks = [
    { to: "/", text: translate("home_txt") },
    { to: "/about", text: translate("about_txt") },
    { to: "/pricing", text: translate("price_txt") },
    { to: "/payment", text: translate("payment_link") },
    { to: "http://192.168.106.3", text: translate("speed_txt") },
    { to: "/contact", text: translate("contact_txt") },
  ];
  const isLinkActive = (link) => {
    return location.pathname === link ? "active" : "";
  };

  const [showSideMenu, setShowSideMenu] = useState(false);
  const showHideClassName = showSideMenu
    ? "offcanvas__menu__overlay active"
    : "offcanvas__menu__overlay";
  const showHideClassNam2 = showSideMenu
    ? "offcanvas__menu__wrapper active show__offcanvas__menu"
    : "offcanvas__menu__wrapper";
  const HideSideMenu = () => {
    setShowSideMenu(false);
  };
  const DisplaySideMenu = () => {
    setShowSideMenu(true);
    setTimeout(() => {
      setShowSideMenu(false);
    }, 2000);
  };

  return (
    <>
      {showPay && (
        <section id="payment_select" className="services-section spad cop">
          <div className="card-container w-3/5 md:w-2/5">
            <div className="card w-full">
              <button onClick={handleHidePay} className="dismiss">
                x
              </button>
              <div className="header">
                <div className="content">
                  <ul className="message">
                    <div className="w-full flex flex-col items-center justify-center wrap px-4">
                      <button
                        data-translate="cash_pay"
                        onClick={showCashPay}
                        className="mt-3 px-3 py-2 bg-slate-600 text-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-[13.22rem] lg:w-[54%] rounded-md sm:text-sm focus:ring-1"
                      >
                        {translate("cash_pay")}
                      </button>
                      {showLocation && (
                        <select
                          name="locations"
                          id="locations"
                          className=" mt-3 px-3 py-2 border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-[13.22rem] lg:w-[54%] rounded-md sm:text-sm focus:ring-1"
                          onChange={handleLocationChange}
                          value={selectedLocation}
                        >
                          <option value="Ünalmar Market - Haspolat">
                            Ünalmar Market - Haspolat
                          </option>
                          <option value="Uzmar Market - Alayköy">
                            Uzmar Market - Alayköy
                          </option>
                          <option value="Tom & Bony Market - Lefkoşa">
                            Tom & Bony Market - Lefkoşa
                          </option>
                          <option value="Servet Karadağ Barber Shop - Lefkoşa">
                            Servet Karadağ Barber Shop - Lefkoşa
                          </option>
                          <option value="Mineraliköy Kooperatifi - Lefkoşa">
                            Mineraliköy Kooperatifi - Lefkoşa
                          </option>
                          <option value="AlemNet Merkez ofis - Dereboyu">
                            AlemNet Merkez ofis - Dereboyu
                          </option>
                          <option value="Alemdar Network Merkez ofis - Lefkoşa">
                            Alemdar Network Merkez ofis - Lefkoşa
                          </option>
                        </select>
                      )}
                    </div>

                    <div className="w-full flex flex-col items-center justify-center wrap px-4">
                      <button
                        onClick={goPayPage}
                        className=" mt-3 px-3 py-2 bg-blue-600 text-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-[13.22rem] lg:w-[54%] rounded-md sm:text-sm focus:ring-1"
                      >
                        {translate("online_payment")}
                      </button>
                    </div>
                    <div className="w-full flex flex-col items-center justify-center wrap px-4">
                      <button
                        data-translate="go_location"
                        onClick={handleGoLocation}
                        className=" mt-3 px-3 py-2 bg-green-600 text-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-[13.22rem] lg:w-[54%] rounded-md sm:text-sm focus:ring-1"
                      >
                        {translate("go_location")}
                      </button>
                    </div>
                    <div className="w-full flex flex-col items-center justify-center wrap px-4">
                      <button
                        data-translate="bank_trans_btn"
                        onClick={showBankAcc}
                        className="mt-3 px-3 py-2 bg-slate-600 text-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-[13.22rem] lg:w-[54%] rounded-md sm:text-sm focus:ring-1"
                      >
                        {translate("bank_trans_btn")}
                      </button>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <div className={showHideClassName}></div>
      {/* add or remove from top -> active and from bottom -> show__offcanvas__menu on click on the menu icon */}
      <div className={showHideClassNam2}>
        <div className="canvas__close" onClick={HideSideMenu}>
          <span className="fa fa-times-circle-o"></span>
        </div>
        <div className="offcanvas__logo">
          <Link to="/">
            <img src="img/logo.png" alt="" />
          </Link>
        </div>
        <nav className="offcanvas__menu mobile-menu">
          <ul>
            <li className="active">
              <Link
                data-translate="home_txt"
                to="/"
                style={{ textTransform: "uppercase" }}
                previewlistener="true"
              >
                {translate("home_txt")}
              </Link>
            </li>
            <li>
              <Link
                data-translate="about_txt"
                to="/about"
                previewlistener="true"
                style={{ textTransform: "uppercase" }}
              >
                {translate("about_txt")}
              </Link>
            </li>
            <li>
              <Link
                data-translate="price_txt"
                to="/pricing"
                previewlistener="true"
                style={{ textTransform: "uppercase" }}
              >
                {translate("price_txt")}
              </Link>
            </li>
            <li>
              <a
                href="http://192.168.106.3"
                data-translate="speed_txt"
                previewlistener="true"
                style={{ textTransform: "uppercase" }}
              >
                {translate("speed_txt")}
              </a>
            </li>

            <li>
              <Link
                data-translate="contact_txt"
                to="/contact"
                previewlistener="true"
                style={{ textTransform: "uppercase" }}
              >
                {translate("contact_txt")}
              </Link>
            </li>

            <li>
              <div className="langList">
                <img
                  src="https://flagcdn.com/48x36/gb.png"
                  alt="English"
                  style={{
                    width: "1.25rem",
                    height: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => handleLanguageChange("en")}
                />
                <img
                  src="https://flagcdn.com/48x36/tr.png"
                  alt="Turkish"
                  style={{
                    width: "1.25rem",
                    height: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => handleLanguageChange("tr")}
                />
                <img
                  src="https://flagcdn.com/48x36/ru.png"
                  alt="Russian"
                  style={{
                    width: "1.25rem",
                    height: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => handleLanguageChange("ru")}
                />
              </div>
            </li>
          </ul>
        </nav>
        <div id="mobile-menu-wrap">
          <div className="slicknav_menu">
            <div
              aria-haspopup="true"
              role="button"
              tabindex="0"
              className="slicknav_btn slicknav_collapsed"
              style={{ outline: "none" }}
            >
              <span className="slicknav_menutxt">MENU</span>
              <span className="slicknav_icon">
                <span className="slicknav_icon-bar"></span>
                <span className="slicknav_icon-bar"></span>
                <span className="slicknav_icon-bar"></span>
              </span>
            </div>
            <nav
              className="slicknav_nav slicknav_hidden"
              style={{ display: "none" }}
              aria-hidden="true"
              role="menu"
            >
              <ul>
                <li className="active">
                  <Link
                    data-translate="home_txt"
                    to="/"
                    role="menuitem"
                    previewlistener="true"
                    style={{ textTransform: "uppercase" }}
                  >
                    {translate("home_txt")}
                  </Link>
                </li>
                <li>
                  <Link
                    data-translate="about_txt"
                    to="/about"
                    style={{ textTransform: "uppercase" }}
                    role="menuitem"
                    previewlistener="true"
                  >
                    {translate("about_txt")}
                  </Link>
                </li>
                <li>
                  <Link
                    data-translate="price_txt"
                    to="/pricing"
                    role="menuitem"
                    style={{ textTransform: "uppercase" }}
                    previewlistener="true"
                  >
                    {translate("price_txt")}
                  </Link>
                </li>
                <li>
                  <a
                    href="http://192.168.106.3"
                    data-translate="speed_txt"
                    role="menuitem"
                    previewlistener="true"
                    style={{ textTransform: "uppercase" }}
                  >
                    {translate("speed_txt")}
                  </a>
                </li>

                <li>
                  <Link
                    data-translate="contact_txt"
                    to="/contact"
                    style={{ textTransform: "uppercase" }}
                    role="menuitem"
                    previewlistener="true"
                  >
                    {translate("contact_txt")}
                  </Link>
                </li>

                <li>
                  <div className="langList">
                    <img
                      src="https://flagcdn.com/48x36/gb.png"
                      alt="English"
                      style={{
                        width: "1.25rem",
                        height: "1rem",
                        cursor: "pointer",
                      }}
                      onClick={() => handleLanguageChange("en")}
                    />
                    <img
                      src="https://flagcdn.com/48x36/tr.png"
                      alt="Turkish"
                      style={{
                        width: "1.25rem",
                        height: "1rem",
                        cursor: "pointer",
                      }}
                      onClick={() => handleLanguageChange("tr")}
                    />
                    <img
                      src="https://flagcdn.com/48x36/ru.png"
                      alt="Russian"
                      style={{
                        width: "1.25rem",
                        height: "1rem",
                        cursor: "pointer",
                      }}
                      onClick={() => handleLanguageChange("ru")}
                    />
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="offcanvas__info">
          <ul>
            <a href="tel:+90 5428772009">
              <li>
                <span className="icon_phone"></span> +90 5428772009
              </li>
            </a>
          </ul>
        </div>
      </div>
      <div className="header-section">
        <div className="header_infos p-[10px] bg-[#120851] hidden md:flex">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="header__info-left">
                  <ul>
                    <li>
                      <a href="tel:+90 5428772009">
                        <span className="icon_phone"></span> +90 5428772009
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="header__info-right">
                  <ul>
                    <li>
                      <a href="mailto:alemdar_488@hotmail.com">
                        <span className="fa fa-envelope"></span>
                        alemdar_488@hotmail.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container"
          style={{ backgroundColor: "white", maxWidth: "100% !important" }}
        >
          <div className="row">
            <div className="col-lg-3 col-md-3">
              <div className="header__logo">
                <Link previewlistener="true">
                  <img src="img/logo.png" alt="" />
                </Link>
              </div>
            </div>
            <div className="col-lg-9 col-md-9">
              <nav className="header__menu">
                <ul>
                  {navLinks.map((link, index) => (
                    <li key={index} className={isLinkActive(link.to)}>
                      {link.to === "/payment" ? (
                        <div
                          style={{ textTransform: "uppercase" }}
                          onClick={handleHidePay}
                        >
                          {link.text}
                        </div>
                      ) : link.to === "http://192.168.106.3" ? (
                        <a href={link.to} previewlistener="true">
                          {link.text}
                        </a>
                      ) : (
                        <Link to={link.to} previewlistener="true">
                          {link.text}
                        </Link>
                      )}
                    </li>
                  ))}
                  <li>
                    <div
                      className=""
                      onClick={toggleLangBtn}
                      data-translate="lang_icon"
                    >
                      <img
                        src={flagUrl}
                        style={{ width: "1.25rem", height: "1rem" }}
                        alt="lang"
                      />
                    </div>
                    {isOpen && (
                      <div className="langLists" id="lang">
                        <img
                          src="https://flagcdn.com/48x36/gb.png"
                          alt="English"
                          style={{
                            width: "1.25rem",
                            height: "1rem",
                            cursor: "pointer",
                          }}
                          onClick={() => handleLanguageChange("en")}
                        />
                        <img
                          src="https://flagcdn.com/48x36/tr.png"
                          alt="Turkish"
                          style={{
                            width: "1.25rem",
                            height: "1rem",
                            cursor: "pointer",
                          }}
                          onClick={() => handleLanguageChange("tr")}
                        />
                        <img
                          src="https://flagcdn.com/48x36/ru.png"
                          alt="Russian"
                          style={{
                            width: "1.25rem",
                            height: "1rem",
                            cursor: "pointer",
                          }}
                          onClick={() => handleLanguageChange("ru")}
                        />
                      </div>
                    )}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div
            className="canvas__open"
            style={{ width: "fit-content", right: "3rem" }}
          >
            <span className="stlx">
              <div
                data-translate="payment_link"
                id="payment_link_1"
                className="text-black"
                style={{ textTransform: "uppercase" }}
                onClick={handleHidePay}
              >
                {translate("payment_link")}
              </div>
            </span>
          </div>
          <button className="canvas__open" onClick={DisplaySideMenu}>
            <span className="fa fa-bars"></span>
          </button>
        </div>
      </div>
    </>
  );
}

export default Header;
