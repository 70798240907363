import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
function VisitUs() {
  const { t: translate } = useTranslation();
  return (
    <>
      <section className="work-section spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h3 data-translate="lthlp">{translate("lthlp")}</h3>
              </div>
              <div className="work__text">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="work__item">
                      <i className="fa fa-desktop"></i>
                      <span
                        style={{ textTransform: "uppercase" }}
                        data-translate="chkt"
                      >
                        {translate("chkt")}
                      </span>
                      <h3
                        style={{ textTransform: "uppercase" }}
                        data-translate="int_p"
                      >
                        {translate("int_p")}
                      </h3>
                      <p data-translate="int_p_txt">{translate("int_p_txt")}</p>
                      <Link
                        className="primary-btn"
                        data-translate="ch_n"
                        to="/pricing"
                        previewlistener="true"
                      >
                        {translate("ch_n")}
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="work__item">
                      <i className="fa fa-shopping-bag"></i>
                      <span
                        style={{ textTransform: "uppercase" }}
                        data-translate="vzt"
                      >
                        {translate("vzt")}
                      </span>
                      <h3
                        style={{ textTransform: "uppercase" }}
                        data-translate="vzt_st"
                      >
                        {translate("vzt_st")}
                      </h3>
                      <p data-translate="vzt_txt">{translate("vzt_txt")}</p>
                      <Link
                        className="primary-btn"
                        data-translate="ch_n"
                        to="/contact#maps"
                      >
                        {translate("ch_n")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="choose-plan-section spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <img src="img/choose-plan.png" alt="" />
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="plan__text">
                <h3 data-translate="fst_dsc">{translate("fst_dsc")}</h3>
                <ul>
                  <li>
                    <span
                      className="fa fa-check"
                      data-translate="fst_txt_1"
                    ></span>{" "}
                    {translate("fst_txt_1")}
                  </li>{" "}
                  <li>
                    <span
                      className="fa fa-check"
                      data-translate="fst_txt_2"
                    ></span>{" "}
                    {translate("fst_txt_2")}
                  </li>{" "}
                  <li>
                    <span
                      className="fa fa-check"
                      data-translate="fst_txt_3"
                    ></span>{" "}
                    {translate("fst_txt_3")}
                  </li>{" "}
                  <li>
                    <span
                      className="fa fa-check"
                      data-translate="fst_txt_4"
                    ></span>{" "}
                    {translate("fst_txt_4")}
                  </li>
                </ul>
                <a
                  href="#packages"
                  className="primary-btn"
                  data-translate="get_strt"
                >
                  {translate("get_strt")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default VisitUs;
