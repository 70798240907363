import React from "react";
import Packages from "../components/Packages";
import { Link } from "react-router-dom";
import PopUp from "../components/PopUp";
import ContactForm from "../components/ContactForm";
import { useTranslation } from "react-i18next";
function Pricing({ handleHidePack }) {
  const { t: translate } = useTranslation();
  return (
    <div className="mt-[5rem] md:mt-[6rem]">
      <PopUp />
      <div className="breadcrumb-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb__option">
                <Link data-translate="home_txt" to="/" previewlistener="true">
                  <span className="fa fa-home"></span> {translate("home_txt")}
                </Link>
                <span data-translate="price_txt">{translate("price_txt")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Packages handleHidePack={handleHidePack} />
      <section className="choose-plan-section pricing-page spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <img src="img/choose-plan.png" alt="" />
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="plan__text">
                <h3 data-translate="fst_dsc">{translate("fst_dsc")}</h3>
                <ul>
                  <li>
                    <span
                      className="fa fa-check"
                      data-translate="fst_txt_1"
                    ></span>{" "}
                    {translate("fst_txt_1")}
                  </li>{" "}
                  <li>
                    <span
                      className="fa fa-check"
                      data-translate="fst_txt_2"
                    ></span>{" "}
                    {translate("fst_txt_2")}
                  </li>{" "}
                  <li>
                    <span
                      className="fa fa-check"
                      data-translate="fst_txt_3"
                    ></span>{" "}
                    {translate("fst_txt_3")}
                  </li>{" "}
                  <li>
                    <span
                      className="fa fa-check"
                      data-translate="fst_txt_4"
                    ></span>{" "}
                    {translate("fst_txt_4")}
                  </li>
                </ul>
                <Link
                  className="primary-btn"
                  data-translate="get_strt"
                  to="/contact"
                  previewlistener="true"
                >
                  {translate("get_strt")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactForm />
    </div>
  );
}

export default Pricing;
