import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import "./pay-done.css";

function PaymentForm({ pack }) {
  const { t: translate } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState("false");
  const [countdown, setCountdown] = useState(15);

  useEffect(() => {
    // Start countdown and handle redirection
    if (countdown > 0 && loading === "DONE") {
      const interval = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);

      // Clear interval when component unmounts or countdown is finished
      return () => clearInterval(interval);
    }

    // Redirect when countdown reaches zero
    if (countdown === 0) {
      window.location.href = "/";
    }
  }, [countdown, loading]);

  const linkRequest = async () => {
    if (!phoneNumber || !isValidTurkishPhoneNumber(phoneNumber)) {
      toast.error(translate("err_phone"));
      return;
    }

    const handleError = (error, message) => {
      console.error(message, error);
      toast.error(translate("errr_link"));
    };

    const now = new Date();
    const formattedDate = formatDate(now);

    try {
      setLoading("true");
      const api_base_url = process.env.REACT_APP_API_URL;

      await axios.post(`${api_base_url}/internet/package/payment`, {
        userName: userName,
        clientPhone: phoneNumber,
        amount: pack.price,
        currency: "TRY",
        userLanguage: "tr",
        packageName: `${pack.speed} ${pack.type}`,
        paymentDate: formattedDate,
        refMerchantStore: 704,
      });

      toast.success(translate("scss_link"));
      setLoading("DONE");

      setCountdown(15);
    } catch (error) {
      handleError(error, "Error sending request or email.");
    }
  };

  const formatDate = (date) => {
    const options = {
      month: "numeric",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const isValidTurkishPhoneNumber = (number) => {
    const regex = /^(?:\+90|0)?\s?\d{10}$/;
    return regex.test(number.replace(/\s+/g, ""));
  };

  return (
    <div className="lg:w-[100%] flex flex-col items-center">
      <div className="contact-form spad w-[90%]">
        <div className="container">
          <div className="col-lg-12">
            <h3>{translate("online_payment")}</h3>
            {loading === "false" && (
              <div className="master-container">
                <div className="card coupons">
                  <form className="form">
                    <label className="title" style={{ fontSize: "20px" }}>
                      {translate("req_name")}
                    </label>
                    <input
                      type="text"
                      placeholder={translate("ent_user_name")}
                      className="input_field"
                      onChange={(event) => setUserName(event.target.value)}
                      value={userName}
                    />
                    <label className="title" style={{ fontSize: "20px" }}>
                      {translate("phone_num")}
                    </label>
                    <input
                      type="text"
                      placeholder={translate("ent_phone_number")}
                      className="input_field"
                      onChange={(event) => setPhoneNumber(event.target.value)}
                      value={phoneNumber}
                    />
                  </form>
                </div>
                <div className="card checkout">
                  <label className="title" style={{ fontSize: "20px" }}>
                    {translate("pck_details")}
                  </label>
                  <div className="details">
                    <span style={{ fontSize: "20px" }}>
                      {translate("pck_name")}
                    </span>
                    <span style={{ textTransform: "uppercase" }}>
                      {pack?.speed}
                    </span>
                    <span style={{ fontSize: "20px" }}>
                      {translate("pck_type")}
                    </span>
                    <span style={{ textTransform: "uppercase" }}>
                      {pack?.type}
                    </span>
                    <span style={{ fontSize: "20px" }}>
                      {translate("pck_price")}
                    </span>
                    <span>TRY {pack?.price}</span>
                  </div>
                  <div className="checkout--footer">
                    <label className="price">
                      <sup>TRY </sup>
                      {pack?.price}
                    </label>
                    <button
                      onClick={linkRequest}
                      className="checkout-btn"
                      style={{ fontSize: "20px" }}
                    >
                      {translate("checkout")}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {loading === "true" && (
              <div className="w-full flex justify-center container">
                <div className="crard">
                  <div className="header">
                    <div className="image">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <g stroke-width="0" id="SVGRepo_bgCarrier"></g>
                        <g
                          stroke-linejoin="round"
                          stroke-linecap="round"
                          id="SVGRepo_tracerCarrier"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            stroke-linejoin="round"
                            stroke-linecap="round"
                            stroke-width="1.5"
                            stroke="#000000"
                            d="M20 7L9.00004 18L3.99994 13"
                          ></path>{" "}
                        </g>
                      </svg>
                    </div>
                    <div className="content">
                      <span className="title">{translate("ord_valid")}</span>
                      <p className="message">{translate("thnk_msg")}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {loading === "DONE" && (
              <div className="w-full flex justify-center container">
                <div className="crard">
                  <div className="header">
                    <div className="image">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <g stroke-width="0" id="SVGRepo_bgCarrier"></g>
                        <g
                          stroke-linejoin="round"
                          stroke-linecap="round"
                          id="SVGRepo_tracerCarrier"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            stroke-linejoin="round"
                            stroke-linecap="round"
                            stroke-width="1.5"
                            stroke="#000000"
                            d="M20 7L9.00004 18L3.99994 13"
                          ></path>{" "}
                        </g>
                      </svg>
                    </div>
                    <div className="content">
                      <span className="title">{translate("ord_valid")}</span>
                      <p className="message">{translate("thnk_msg")}</p>
                    </div>
                    <div className="actions">
                      <button type="button" className="history">
                        {translate("redirecting")} {countdown}{" "}
                        {translate("seconds")}...
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentForm;
