import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./screens/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./screens/About";
import Pricing from "./screens/Pricing";
import Contact from "./screens/Contact";
import { useEffect, useState } from "react";

import "./i18n";
import Faq from "./screens/Faq";
import BankAccounts from "./screens/BankAccounts";
import LoaderComponent from "./components/LoaderComponent";
import Payment from "./screens/Payment";
import { Toaster } from "react-hot-toast";
// import { useTranslation } from "react-i18next";

function App() {
  const [showPack, setShowPack] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
    window.scrollTo(0, 0);
  }, [location]);
  const handleHidePack = () => {
    setShowPack(!showPack);
  };

  return (
    <div className="App">
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <>
          <Header handleHidePack={handleHidePack} showPack={showPack} />
          <Toaster />
          <Routes>
            <Route
              path="*"
              element={<Home handleHidePack={handleHidePack} />}
            />
            <Route
              path="/"
              element={<Home handleHidePack={handleHidePack} />}
            />
            <Route path="/about" element={<About />} />
            <Route
              path="/payment"
              element={<Payment handleHidePack={handleHidePack} />}
            />
            <Route
              path="/pricing"
              element={<Pricing handleHidePack={handleHidePack} />}
            />
            <Route path="/contact" element={<Contact />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/bank_accounts" element={<BankAccounts />} />
          </Routes>
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
